import React from 'react'
import australianGoldLogo from '../resources/images/australian_gold_logo.png'
import playstoreButton from '../resources/images/playstore_button.png'
import appstoreButton from '../resources/images/appstore_button.png'

const HomePage: React.FC = () => {
    return (
        <div className="o-main-page-container">
            <div className="o-main-logo-container">
                <img alt="Australian Gold" src={australianGoldLogo} />
            </div>
            <div className="o-main-text-container">
                <div className="o-main-text-container__left-panel">
                    <h1>Australian Gold App</h1>
                    <p>
                        Con l’app Australian Gold, scopri i consigli per un’abbronzatura perfetta, i punti vendita più
                        vicini a te e le migliori spiagge!
                    </p>
                    <p>
                        Scarica l’app e segui i consigli dei migliori esperti per avere <b>12 mesi</b> di idratazione,
                        abbronzatura e make up.
                    </p>
                    <div className="c-buttons-container">
                        <div className="c-buttons-container__button">
                            <a href="https://apps.apple.com/it/app/australian-gold-italia/id824322889">
                                <img
                                    aria-label="Scarica su App Store"
                                    alt="Scarica su App Store"
                                    src={appstoreButton}
                                />
                            </a>
                        </div>
                        <div className="c-buttons-container__button">
                            <a href="https://play.google.com/store/apps/details?id=com.hbg.australiangold">
                                <img
                                    aria-label="Disponibile su Google Play"
                                    alt="Disponibile su Google Play"
                                    src={playstoreButton}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="o-main-text-container__right-panel">
                    <div className="o-phone-screen" />
                </div>
            </div>
        </div>
    )
}

export default HomePage
